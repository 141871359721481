import MockAdapter from 'axios-mock-adapter/types';
import { APP_CONFIG } from '../../../config';
import { PendingSaleItem, PendingSaleTiming } from '../model/types';

const SECURITY_DETAILS_MOCK: PendingSaleItem[] = [
  {
    type: 16,
    timing: PendingSaleTiming.GTD,
    datePlaced: '2017.11.11',
    accountNumber: 1123123131,
    companyName: 'Company A',
    securityType: 'Common',
    shareType: 'DRS',
    shares: 1213,
    orderPrice: 10,
    expirationDate: '2019.11.11',
    saleId: 1,
  },
  {
    type: 16,
    timing: PendingSaleTiming.GTD,
    datePlaced: '2018.11.11',
    accountNumber: 1123123131,
    companyName: 'Company A',
    securityType: 'Common',
    shareType: 'DRS',
    shares: 1213,
    orderPrice: 10,
    expirationDate: '2019.11.11',
    saleId: 2,
  },
];

export function mockGetPendingSales(mock: MockAdapter) {
  mock
    .onGet(APP_CONFIG.api.securityOverview.sales.getPendingSales)
    .reply(async () => [200, { success: true, data: SECURITY_DETAILS_MOCK }]);
}

export function mockGetPendingSale(mock: MockAdapter) {
  const urlWithParameters = APP_CONFIG.api.securityOverview.sales.getPendingSale(1);
  mock
    .onGet(urlWithParameters)
    .reply(async () => [200, { success: true, data: SECURITY_DETAILS_MOCK[0] }]);
}

export function mockCancelPendingSale(mock: MockAdapter) {
  const urlWithParameters = APP_CONFIG.api.securityOverview.sales.cancelPendingSale(1123123131, 1);
  mock.onDelete(urlWithParameters).reply(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve([200, { success: true }]), 1000);
    });
  });
}
