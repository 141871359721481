import { ConnectedRouter } from 'connected-react-router';
import React, { Suspense, lazy } from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { AppBootstrap } from './AppBootstrap';
import { logError } from './helpers/logging';
import { Spinner } from './lib/Spinner/Spinner';
import { NotFound } from './NotFound';
import { Alias, RoutingInfo, useRouting } from './routing/api';
import { ROUTES } from './routing/routes';
import { UnreadAlertsPortal } from './shared/UnreadAlerts/UnreadAlertsPortal';
import { UnreadMessagesPortal } from './shared/UnreadMessages/UnreadMessagesPortal';
import { history } from './store';
import { ScrollToTopProvider } from './helpers/ScrollToTopProvider';
import { useBranding } from './style/theme/hooks';
import { HeaderPortal } from './shared/Header/HeaderPortal';
import { FooterPortal } from './shared/Footer/FooterPortal';

const LOGIN_ROUTE = 'login';
const AUTH_SETTINGS_ROUTE = 'authSettings';

const ContactUs = lazy(() =>
  import('./pages/AlertsAndMessages/ContactUs/ContactUsContainer').then(({ ContactUs }) => ({
    default: ContactUs,
  })),
);
const MessageContainer = lazy(() =>
  import('./pages/AlertsAndMessages/Message/MessageContainer').then(({ MessageContainer }) => ({
    default: MessageContainer,
  })),
);
const Contact = lazy(() =>
  import('./pages/Contact/Contact').then(({ Contact }) => ({
    default: Contact,
  })),
);
const EmailAddressMismatchPage = lazy(() =>
  import('./pages/EmailAddressMismatch').then(({ EmailAddressMismatchPage }) => ({
    default: EmailAddressMismatchPage,
  })),
);
const SignOnIssuesRouter = lazy(() =>
  import('./pages/SignOn/Issues/SignOnIssuesRouter').then(({ SignOnIssuesRouter }) => ({
    default: SignOnIssuesRouter,
  })),
);
const TermsAndConditionsPage = lazy(() =>
  import('./pages/TermsAndConditions').then(({ TermsAndConditionsPage }) => ({
    default: TermsAndConditionsPage,
  })),
);
const SignUpPage = lazy(() =>
  import('./pages/SignUp').then(({ SignUpPage }) => ({ default: SignUpPage })),
);
const Checks = lazy(() =>
  import('./pages/Checks/index').then(({ Checks }) => ({ default: Checks })),
);
const Dashboard = lazy(() =>
  import('./pages/Dashboard/Dashboard').then(({ Dashboard }) => ({ default: Dashboard })),
);
const Portfolio = lazy(() =>
  import('./pages/Portfolio/Portfolio').then(({ Portfolio }) => ({ default: Portfolio })),
);
const BuyShares = lazy(() =>
  import('./pages/Shares/Buy').then(({ BuyShares }) => ({ default: BuyShares })),
);
const DsppPage = lazy(() =>
  import('./pages/Dspp/DsppPage').then(({ DsppPage }) => ({ default: DsppPage })),
);
const EsppPage = lazy(() => import('./pages/Espp').then(({ EsppPage }) => ({ default: EsppPage })));
const AccountSettings = lazy(() =>
  import('./pages/AccountSettings/AccountSettings').then(({ AccountSettings }) => ({
    default: AccountSettings,
  })),
);
const DocumentsAndForms = lazy(() =>
  import('./pages/DocumentsAndForms/DocumentsAndForms').then(({ DocumentsAndForms }) => ({
    default: DocumentsAndForms,
  })),
);
const AlertsAndMessages = lazy(() =>
  import('./pages/AlertsAndMessages/AlertsAndMessagesContainer').then(({ AlertsAndMessages }) => ({
    default: AlertsAndMessages,
  })),
);
const CompanyPlansPage = lazy(() =>
  import('./pages/CompanyPlans').then(({ CompanyPlansPage }) => ({ default: CompanyPlansPage })),
);
const PendingSaleContainer = lazy(() =>
  import('./pages/PendingSales').then(({ PendingSaleContainer }) => ({
    default: PendingSaleContainer,
  })),
);
const SellSharesContainer = lazy(() =>
  import('./pages/Shares/Sell/SellShares.container').then(({ SellSharesContainer }) => ({
    default: SellSharesContainer,
  })),
);
const DividendPage = lazy(() =>
  import('./pages/Dividend/DividendPage').then(({ DividendPage }) => ({ default: DividendPage })),
);
const SecurityOverview = lazy(() =>
  import('./pages/SecurityOverview').then(({ SecurityOverview }) => ({
    default: SecurityOverview,
  })),
);
const CostBasisDetails = lazy(() =>
  import('./pages/SecurityOverview/components/CostBasis/Details/CostBasisDetails').then(
    ({ CostBasisDetails }) => ({ default: CostBasisDetails }),
  ),
);
const WfssoPage = lazy(() =>
  import('./pages/Wfsso/WfssoPage').then(({ WfssoPage }) => ({ default: WfssoPage })),
);
const BrokerInstructions = lazy(() =>
  import('./pages/BrokerInstructions/BrokerInstructions').then(({ BrokerInstructions }) => ({
    default: BrokerInstructions,
  })),
);
const CountryGlossaryPage = lazy(() =>
  import('./pages/Help/Glossary').then(({ CountryGlossaryPage }) => ({
    default: CountryGlossaryPage,
  })),
);
const GlossaryPage = lazy(() =>
  import('./pages/Help/Glossary').then(({ GlossaryPage }) => ({ default: GlossaryPage })),
);
const MarketDataPage = lazy(() =>
  import('./pages/MarketData').then(({ MarketDataPageContainer }) => ({
    default: MarketDataPageContainer,
  })),
);

const InternationalPayments = lazy(() =>
  import('./pages/Help/InternationalPayments/InternationalPayments').then(
    ({ internationalPaymentsPage }) => ({
      default: internationalPaymentsPage,
    }),
  ),
);

export function App() {
  const { routing, isLoading } = useRouting();
  const theme = useBranding();

  return (
    <AppBootstrap theme={theme}>
      {isLoading && <Spinner size="lg" />}
      {!isLoading && routing && renderRouter(routing)}
      <HeaderPortal />
      <FooterPortal />
    </AppBootstrap>
  );
}

function renderRouter(routingData: RoutingInfo[]) {
  const loginInfo = routingData.find((r) => r.alias === LOGIN_ROUTE);
  const loginRoute = loginInfo && loginInfo.route;
  return (
    <ConnectedRouter history={history}>
      <Suspense fallback={<Spinner size={'lg'} />}>
        <Switch>
          <ScrollToTopProvider>
            <Route path="/:variation(\w{2}-\w{2})?">
              {(props) => {
                const { match } = props;
                const prefix = match && match.url ? match.url : '';
                return (
                  <Switch>
                    {loginRoute ? (
                      <Route exact path={'/'} render={() => <Redirect to={loginRoute} />} />
                    ) : null}
                    {routingData.map((data) => {
                      return getRoutesProps(data.alias).map((p, i) => {
                        return [
                          <Route key={data.alias + i} {...p} path={`${prefix}${p.path}`} />,
                          <Route
                            key={data.contentId + i}
                            {...p}
                            path={`${prefix}/${data.contentId}.aspx`}
                            exact={false}
                          />,
                          <Route
                            key={'preview' + data.contentId + i}
                            {...p}
                            path={`/umbraco/preview/?id=${data.contentId}`}
                            exact={false}
                          />,
                        ];
                      });
                    })}
                    <Route component={NotFound} />
                  </Switch>
                );
              }}
            </Route>
          </ScrollToTopProvider>
        </Switch>
      </Suspense>
    </ConnectedRouter>
  );
}

function getRoutesProps(alias: Alias | undefined): RouteProps[] {
  switch (alias) {
    case `accountSettings`:
      return [{ exact: true, component: AccountSettings, path: ROUTES.accountSettings }];
    case `authenticatedBuy`:
      return [{ exact: false, component: BuyShares, path: ROUTES.buyShares }];
    case `authenticatedSell`:
      return [{ exact: false, component: SellSharesContainer, path: ROUTES.sellShares }];
    case `brokerInstructions`:
      return [{ exact: false, component: BrokerInstructions, path: ROUTES.brokerInstructions }];
    case `companyPlans`:
      return [{ exact: false, component: CompanyPlansPage, path: ROUTES.companyPlans }];
    case `dashboard`:
      return [{ exact: true, component: Dashboard, path: ROUTES.dashboard }];
    case `dividends`:
      return [{ exact: true, component: DividendPage, path: ROUTES.dividends }];
    case `glossary`:
      return [{ exact: false, component: GlossaryPage, path: ROUTES.glossary }];
    case `countryGlossary`:
      return [{ exact: false, component: CountryGlossaryPage, path: ROUTES.countryGlossary }];
    case `portfolio`:
      return [{ exact: false, component: Portfolio, path: ROUTES.portfolio }];
    case `securityOverview`:
      return [
        { exact: true, component: SecurityOverview, path: ROUTES.securityOverview },
        { exact: true, component: CostBasisDetails, path: ROUTES.costBasisDetails },
      ];
    case `signOnIssues`:
      return [{ exact: false, component: SignOnIssuesRouter, path: ROUTES.signOnIssues }];
    case `alertsAndMessages`:
      return [
        { exact: true, component: ContactUs, path: ROUTES.alertsAndMessagesContactUs },
        { exact: false, component: MessageContainer, path: ROUTES.individualMessage },
        { exact: false, component: AlertsAndMessages, path: ROUTES.alertsAndMessages },
      ];
    case `checks`:
      return [{ exact: false, component: Checks, path: ROUTES.checks }];
    case `contact`:
      return [{ exact: false, component: Contact, path: ROUTES.contact }];
    case `signUpProcessPage`:
      return [{ exact: false, component: SignUpPage, path: ROUTES.signUp }];
    case `documentsAndForms`:
      return [{ exact: false, component: DocumentsAndForms, path: ROUTES.documentsAndForms }];
    case `pendingSales`:
      return [{ exact: false, component: PendingSaleContainer, path: ROUTES.pendingSale }];
    case `espp`:
      return [{ exact: false, component: EsppPage, path: ROUTES.espp }];
    case `dspp`:
      return [{ exact: false, component: DsppPage, path: ROUTES.dspp }];
    case `termsAndConditions`:
      return [{ exact: false, component: TermsAndConditionsPage, path: ROUTES.termsAndConditions }];
    case `emailAddressMismatch`:
      return [
        { exact: false, component: EmailAddressMismatchPage, path: ROUTES.emailAddressMismatch },
      ];
    case `wfsso`:
      return [{ exact: false, component: WfssoPage, path: ROUTES.wfsso }];
    case `marketData`:
      return [{ exact: false, component: MarketDataPage, path: ROUTES.marketData }];
    case `internationalPayments`:
      return [
        { exact: false, component: InternationalPayments, path: ROUTES.internationalPayments },
      ];
    default:
      // Login route is processed outside this switch scope, so we don't want to log it
      alias !== LOGIN_ROUTE &&
        alias !== AUTH_SETTINGS_ROUTE &&
        logError(`Can't resolve route props for alias: '${alias}'`);
      return [];
  }
}
