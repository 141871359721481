import { SaleType, SellShare, ShareType } from '../Sell/models';

const fiscalNumberRegex = /^[0-9]*(\.[0-9]{1,3})?$/;
const wholeNumberRegex = /^[0-9]*$/;
const nonDigitRegex = /\D/g;

export const validatePhoneNumber = (phone: string): string => {
  const strippedPhone = phone.replace(nonDigitRegex, '');

  return strippedPhone.length === 10 ? strippedPhone : '';
};

export function isFiscalNumberCorrect(allowFractional: boolean) {
  const test = allowFractional ? fiscalNumberRegex : wholeNumberRegex;
  return (value: string) => {
    const testValue = !!value ? value.toString().trim() : '';
    return test.test(testValue);
  };
}

export function getCanSellPlanShares(securityType: SellShare) {
  return (
    securityType.allowSaleOfPlanShares &&
    securityType.allowPlanSalesSol &&
    securityType.planAvailableForSale > 0
  );
}

export function getCanSellDRSShares(securityType: SellShare) {
  return (
    securityType.allowSaleOfDrs &&
    securityType.allowDrsSalesSol &&
    securityType.drsAvailableForSale > 0
  );
}

export function getPlanAvailableForSale(securityType: SellShare) {
  const canSellPlanShares = getCanSellPlanShares(securityType);
  return canSellPlanShares ? securityType.planAvailableForSale : 0;
}

export function getDrsAvailableForSale(securityType: SellShare) {
  const canSellDRSShares = getCanSellDRSShares(securityType);
  return canSellDRSShares ? securityType.drsAvailableForSale : 0;
}

export function getIsDRSSale(shareType: ShareType): boolean {
  return [ShareType.DrsAndPlanShare, ShareType.DrsShare].includes(shareType);
}

export function getIsPlanSale(shareType: ShareType): boolean {
  return [ShareType.DrsAndPlanShare, ShareType.PlanShare].includes(shareType);
}

export function getIsInstantSale(saleType: SaleType) {
  return [SaleType.MarketOrder, SaleType.BatchOrder].includes(saleType);
}

export function getIsDelayedSale(saleType: SaleType) {
  return [SaleType.LimitOrder, SaleType.StopLossOrder].includes(saleType);
}
