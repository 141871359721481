import { Country } from '../Country';

export interface BankAccount {
  bankName: string;
  routingNumber: string;
  bankAccountNumber: string;
  bankAccountType: BankAccountType;
  currency: string | null;
  destinationCountry: Country;
  bankCity: string | null;
  userRegionCode: string;
  paymentMethodType: PaymentMethodType | '';
  usBeneficiaryABARoutingNumber: string | null;
  usBeneficiaryBankCity: string | null;
  beneficiaryBankAccountNumber: string | null;
  beneficiaryABARoutingNumber: string | null;
  beneficiaryBankAccountName: string | null;
  beneficiaryInstructions: string | null;
  beneficiaryBankSwiftCode: string | null;
  bankAccountHoldersCity: string | null;
  bankAccountHoldersCountry: Country | null;
  internationalRoutingCode: string | null;
}

export enum BankAccountType {
  None = '',
  Checking = 'Checking',
  Savings = 'Savings',
}

export enum PaymentMethodType {
  US = 'D',
  WireTransferUS = 'U',
  International = 'I',
}
