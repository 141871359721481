import { APP_CONFIG } from '../../../../config';
import MockAdapter from 'axios-mock-adapter/types';
import { countries } from '../../Address/store/__mock__/address.commons.mock';
import { TAX_CERTIFICATION_DATA_MOCK } from './taxCertificationData.commons.mock';

export function mockTaxCertificationData(mock: MockAdapter) {
  mock.onGet(APP_CONFIG.api.taxCertification.load).reply(
    () =>
      new Promise(function(resolve) {
        setTimeout(() => resolve([200, { success: true, data: TAX_CERTIFICATION_DATA_MOCK }]), 100);
      }),
  );
}

export function mockCitizenshipCountriesData(mock: MockAdapter) {
  mock.onGet(APP_CONFIG.api.taxCertification.countries).reply(
    () =>
      new Promise(function(resolve) {
        setTimeout(() => resolve([200, { success: true, data: countries }]), 100);
      }),
  );
}

export function mockValidateBatchedCertifyTaxId(mock: MockAdapter) {
  const accountNumber = TAX_CERTIFICATION_DATA_MOCK.accounts[0].number;
  const url = APP_CONFIG.api.taxCertification
    .validatetaxcertification(accountNumber)
    .split(accountNumber.toString())[0];
  return mock.onPost(new RegExp(`${url}/*`)).reply(
    () =>
      new Promise(function(resolve) {
        setTimeout(() => resolve([200, { success: true, data: true }]), 100);
      }),
  );
}

export function mockSaveTaxCertificationData(mock: MockAdapter) {
  const accountNumber = TAX_CERTIFICATION_DATA_MOCK.accounts[0].number;
  const url = APP_CONFIG.api.taxCertification
    .save(accountNumber)
    .split(accountNumber.toString())[0];
  return mock.onPost(new RegExp(`${url}/*`)).reply(
    () =>
      new Promise(function(resolve) {
        setTimeout(() => resolve([200, { success: true, data: true }]), 100);
      }),
  );
}
