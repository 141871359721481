import React, { useCallback, useEffect } from 'react';
import { RootState } from '../../store/rootModel';
import { connect, DispatchProp } from 'react-redux';
import { unreadAlertsActions } from './store/unreadAlerts.actions';
import { UnreadRoundel } from './../UnreadRoundel/UnreadRoundel';

interface OwnProps {
  wrapper?: string;
}

const mapStateToProps = (state: RootState) => ({
  data: state.unreadAlerts.data,
  started: state.unreadAlerts.started,
});

export function Component({
  data,
  started,
  dispatch,
  wrapper = 'div',
}: ReturnType<typeof mapStateToProps> & DispatchProp & OwnProps) {
  const init = useCallback(() => dispatch(unreadAlertsActions.init()), [dispatch]);

  useEffect(() => {
    !started && init();
  }, [started, init]);

  return data && data > 0 ? renderComponent() : null;

  function renderComponent() {
    if (wrapper === 'roundel') {
      return <UnreadRoundel>{data}</UnreadRoundel>;
    }
    return (
      <div className={'unread-alerts-badge'} data-testid="unread-alerts-badge">
        {data}
      </div>
    );
  }
}

export const UnreadAlertsBadge = connect(mapStateToProps)(Component);
