import { getYear, isWithinRange, parse as parseDate, setYear } from 'date-fns';
import { BankAccount } from '../../../../shared/DataStructures/BankAccount';
import { getIsInstantSale } from '../../Shared/utils';
import { ShareTransaction } from '../Steps/SaleOrderDetailsStep/SaleOrderDetails.model';
import {
  PaymentMethod,
  SellJourney,
  SellJourneyMetaData,
  SellMethod,
  ShareType,
} from './SellSharesTypes';

export function getSelectedAccount(data: SellJourney, metaData: SellJourneyMetaData) {
  return metaData.accounts.find((a) => a.accountNumber === data.accountNumber);
}

export function getSelectedCompany(data: SellJourney, metaData: SellJourneyMetaData) {
  const account = getSelectedAccount(data, metaData);
  if (!account) {
    return undefined;
  }
  return account.companies.find((c) => c.companyId === data.companyId);
}

export function getSelectedSecurityType(data: SellJourney, metaData: SellJourneyMetaData) {
  const company = getSelectedCompany(data, metaData);
  if (!company) {
    return undefined;
  }

  return company.shares.find((s) => s.securityType === data.securityType);
}

export function getBookLotsFactory(data: SellJourney, metaData: SellJourneyMetaData) {
  return (unitPrice?: number): ShareTransaction[] => {
    if (
      !metaData ||
      !metaData.selectedIssue ||
      !metaData.sellTypes ||
      !metaData.sellTypes.bookLots ||
      !unitPrice
    ) {
      return [];
    }

    const drsSaleDollarsLimit = metaData.sellTypes!.drsSalesDollarLimit || 0;
    const fractionalSaleAllowed =
      metaData.selectedIssue!.drsFractionalSaleAllowed && getIsInstantSale(data.saleType);
    const currentPrice = unitPrice || 1;
    const maxUnits = +(drsSaleDollarsLimit / currentPrice).toFixed(3);
    const mapper = mapBookLot(fractionalSaleAllowed, maxUnits);

    return metaData.sellTypes.bookLots.map(mapper).filter((b) => b.availableForSale > 0);
  };
}

function mapBookLot(allowFractional: boolean, maxUnits: number) {
  return (bookLot: ShareTransaction) => {
    const amount = bookLot.totalShares < maxUnits ? bookLot.totalShares : maxUnits;

    return {
      ...bookLot,
      availableForSale: allowFractional ? amount : Math.floor(amount), // This defines how value will be shown on table column cell
    };
  };
}

export function getTotalSellAmount(journey: SellJourney) {
  const { shareTypesToSell } = journey;
  switch (shareTypesToSell) {
    case ShareType.DrsShare:
      return getDrsSellAmount(journey);
    case ShareType.PlanShare:
      return journey.planSellAmount;
    case ShareType.DrsAndPlanShare:
      return journey.planSellAmount + getDrsSellAmount(journey);
    case ShareType.None:
    default:
      return 0;
  }
}

export function getTotalPaymentAmount(journey: SellJourney, metaData: SellJourneyMetaData) {
  const { shareTypesToSell } = journey;
  switch (shareTypesToSell) {
    case ShareType.DrsShare:
      return getDrsSellPrice(journey, metaData);
    case ShareType.PlanShare:
      return getPlanSellPrice(journey, metaData);
    case ShareType.DrsAndPlanShare:
      return getPlanSellPrice(journey, metaData) + getDrsSellPrice(journey, metaData);
    case ShareType.None:
    default:
      return 0;
  }
}

function getPlanSellPrice(journey: SellJourney, metaData: SellJourneyMetaData) {
  const { planSellAmount, planAutoSellPrice, saleType } = journey;
  const { sharePrice } = metaData;
  if (getIsInstantSale(saleType)) {
    return planSellAmount * (sharePrice || 0);
  }

  return planSellAmount * (planAutoSellPrice || 0);
}

function getDrsSellPrice(journey: SellJourney, metaData: SellJourneyMetaData) {
  const { drsAutoSellPrice, saleType } = journey;
  const drsSellAmount = getDrsSellAmount(journey);
  const { sharePrice } = metaData;
  if (getIsInstantSale(saleType)) {
    return drsSellAmount * (sharePrice || 0);
  }

  return drsSellAmount * (drsAutoSellPrice || 0);
}

export function getDrsSellAmount(journey: SellJourney) {
  if (journey.saleMethod === SellMethod.FIFO) return journey.drsSellAmount;
  if (!journey.bookLotSaleConfig) return 0;
  const values = Object.values(journey.bookLotSaleConfig);
  if (!values) return 0;
  return values.filter((v) => v.isSelling).reduce((acc, value) => acc + value.amountToSell, 0);
}

export function isDirectDeposit(journey: SellJourney) {
  if (!journey || !journey.payoutType) return false;
  return journey.payoutType === PaymentMethod.DirectDeposit;
}

export function getInternationalCurrency(data: SellJourney, metaData: SellJourneyMetaData) {
  const {
    newBankAccount: { countryCode },
  } = data;

  if (!countryCode || !metaData.countries) {
    return '';
  }

  const country = metaData.countries.find((c) => c.country.code === countryCode);
  return (country && country.currency) || '';
}

export function getSellAmount(data: SellJourney): string {
  if (!data) {
    return (0).toFixed(3);
  }
  switch (data.shareTypesToSell) {
    case ShareType.DrsShare:
      return getDrsSellAmount(data).toFixed(3);
    case ShareType.PlanShare:
      return data.planSellAmount.toFixed(3);
    case ShareType.DrsAndPlanShare:
      return (data.planSellAmount + getDrsSellAmount(data)).toFixed(3);
    default:
      return '';
  }
}

export function getIsSellingAll(data: SellJourney, metaData: SellJourneyMetaData) {
  let maxPlanSharesAmount = null;
  let sellAllPlanSharesTerminatesAccount = false;
  if (metaData.selectedIssue) {
    maxPlanSharesAmount = roundAmount(metaData.selectedIssue.planAvailableForSale);
    sellAllPlanSharesTerminatesAccount = metaData.selectedIssue.sellAllPlanSharesTerminatesAccount;
  }

  let isOptedToSellAllPlanShares = false;
  if (
    sellAllPlanSharesTerminatesAccount &&
    maxPlanSharesAmount &&
    maxPlanSharesAmount === data.planSellAmount
  ) {
    isOptedToSellAllPlanShares = true;
  }

  return isOptedToSellAllPlanShares;

  function roundAmount(amount: number) {
    return Math.floor(amount * 1000) / 1000;
  }
}

export function getSelectedBankAccount(
  data: SellJourney,
  metaData: SellJourneyMetaData,
): BankAccount | undefined {
  if (!data!.existingBankAccountNumber) return;
  const accounts = metaData.bankAccounts;
  if (!accounts) return;
  return accounts.find((a) => a.bankAccountNumber === data!.existingBankAccountNumber);
}

export function getAddressLines(data: SellJourney, metaData: SellJourneyMetaData): string[] {
  const selectedAccount = getSelectedAccount(data, metaData);

  if (!selectedAccount) {
    return [];
  }

  const seasonal = selectedAccount.seasonalAddress;
  const primary =
    (selectedAccount.primaryAddress && selectedAccount.primaryAddress.addressLines) || [];
  if (!seasonal || !seasonal.start || !seasonal.end) {
    return primary;
  }

  const now = parseDate(Date.now());
  const seasonStart = parseDate(seasonal.start);
  const seasonEnd = parseDate(seasonal.end);

  if (seasonal.isRecurring) {
    const currentYear = getYear(now);
    setYear(seasonStart, currentYear);
    setYear(seasonEnd, currentYear);
  }

  if (seasonStart <= seasonEnd && isWithinRange(now, seasonStart, seasonEnd)) {
    return seasonal.addressLines as string[];
  }

  return primary;
}
