import React, { useEffect, useCallback } from 'react';
import ReactSelect from 'react-select';
import { IndicatorProps } from 'react-select/lib/components/indicators';
import { ValueType } from 'react-select/lib/types';
import { styled } from '../../../style';
import { Theme } from '../../../style/theme/themeModule';
import { SELECT_CLASS_NAME_PREFIX } from './select.constants';
import { SelectOption, SelectProps, SelectStyleType } from './select.model';
import { getSelectStyle } from './select.styles';
import { SelectArrow } from './SelectArrow';
import { sortBy } from '../../../shared/Data';

export function BaseSelect({
  value,
  onChange,
  onBlur,
  onFocus,
  options,
  styleType,
  disabled,
  placeholder,
  className,
  iconUp,
  iconDown,
  id,
  alphabeticalOrder,
  wrapOptionContentToNewLine,
  ariaLabel,
  isSearchable,
  blurInputOnSelect,
}: SelectProps) {
  const style: SelectStyleType = styleType || 'primary';
  const filteredEmptyOptions = options.filter((option) => option.label);
  alphabeticalOrder &&
    filteredEmptyOptions.sort(sortBy<SelectOption>((o) => o.label.trim().toLowerCase()));
  const onChangeFn = useCallback(
    (value: ValueType<{}>) => !!onChange && onChange(value as SelectOption),
    [onChange],
  );
  // In this effect we want to check, if we have only one option to select.
  // As per business requirement, in such case it should be preselected.
  useEffect(() => {
    const option = options[0];
    if (options.length === 1 && option !== value) {
      onChangeFn(option);
    }
  }, [options, onChangeFn, value]);

  return (
    <Container
      wrapOptionContentToNewLine={wrapOptionContentToNewLine}
      styleType={style}
      id={id && `base-selector-${id}-container`}
      data-testid="base-select-container"
    >
      <ReactSelect
        id={`from-base-selector-${id}`}
        inputId={id} // this kind of code fix Accessibility related bug(s)
        value={value || null}
        onChange={onChangeFn}
        options={filteredEmptyOptions}
        isDisabled={disabled}
        placeholder={placeholder}
        className={className}
        aria-label={ariaLabel}
        classNamePrefix={SELECT_CLASS_NAME_PREFIX}
        components={{
          DropdownIndicator: (indicatorProps: IndicatorProps<any>) => (
            <SelectArrow
              indicatorProps={indicatorProps}
              styleType={style}
              icons={[iconUp, iconDown]}
            />
          ),
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        isSearchable={isSearchable}
        blurInputOnSelect={blurInputOnSelect}
      />
    </Container>
  );
}

interface ContainerProps extends Theme {
  styleType: SelectStyleType;
  wrapOptionContentToNewLine?: boolean;
}

const Container = styled.div<ContainerProps>`
  ${(p: ContainerProps) => getSelectStyle(p.styleType)}
  .sol-react-select__menu {
    z-index: 10;
  }
  ${(p: ContainerProps) =>
    p.wrapOptionContentToNewLine &&
    `.${SELECT_CLASS_NAME_PREFIX}__option {
    white-space: pre-line;
  }`}
`;
