import { ObjectSchemaDefinition, string } from 'yup';
import { BeneficiaryPayment } from '../WireTransferPaymentTypes';

const BEN_NAME_MAX_LENGTH = 35;
const BEN_ADDRESS1_MAX_LENGTH = 35;
const BEN_ADDRESS2_MAX_LENGTH = 35;
const BEN_CITY_MAX_LENGTH = 35;
const BEN_STATE_PROVINCE_MAX_LENGTH = 35;
const BEN_TAX_ID_MAX_LENGTH = 35;
const BEN_EMAIL_MAX_LENGTH = 100;
const BEN_PHONE_MAX_LENGTH = 35;

const BEN_PHONE_NUMBER_REGEXP = /^\d{1,30}$/;
const BANK_ACCOUNT_NAME_REGEXP = /^[a-zA-Z\d\s\-.&,'"()&quot;]{1,35}$/;
const BEN_ADDRESS_REGEXP = /^[\s\w\-/]{0,35}$/;
const BEN_CITY_REGEXP = /^[\da-zA-Z\s]{1,30}$/;
const BEN_STATE_PROVINCE_REGEXP = /^[\s\w]{1,3}$/;
const BEN_TAX_ID_REGEXP = /^\w{1,35}$/;
const BEN_EMAIL_REGEXP = /[a-zA-Z0-9!#$%^&_-]+(?:\.[a-zA-Z0-9!#$%^&_-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;

export interface BeneficiaryPaymentFormValidationTranslations {
  beneficiaryNameRequiredMessage: string;
  beneficiaryNameFormatMessage: string;
  beneficiaryAddress1RequiredMessage: string;
  beneficiaryAddress1FormatMessage: string;
  beneficiaryAddress2FormatMessage: string;
  beneficiaryCityRequiredMessage: string;
  beneficiaryCityFormatMessage: string;
  beneficiaryStateProvinceRequiredMessage: string;
  beneficiaryStateProvinceFormatMessage: string;
  beneficiaryCountryRequiredMessage: string;
  beneficiaryTaxIDRequiredMessage: string;
  beneficiaryTaxIDFormatMessage: string;
  beneficiaryEmailAddressRequiredMessage: string;
  beneficiaryEmailAddressFormatMessage: string;
  beneficiaryTelephoneNumberRequiredMessage: string;
  beneficiaryTelephoneNumberFormatMessage: string;
}
export function getBeneficiaryPaymentFormValidationSchemaDefinition(
  t: BeneficiaryPaymentFormValidationTranslations,
): ObjectSchemaDefinition<BeneficiaryPayment> {
  return {
    beneficiaryName: string()
      .required(t.beneficiaryNameRequiredMessage)
      .max(BEN_NAME_MAX_LENGTH, t.beneficiaryNameFormatMessage)
      .matches(BANK_ACCOUNT_NAME_REGEXP, {
        message: t.beneficiaryNameFormatMessage,
        excludeEmptyString: true,
      }),
    beneficiaryAddress1: string()
      .required(t.beneficiaryAddress1RequiredMessage)
      .max(BEN_ADDRESS1_MAX_LENGTH, t.beneficiaryAddress1FormatMessage)
      .matches(BEN_ADDRESS_REGEXP, {
        message: t.beneficiaryAddress1FormatMessage,
        excludeEmptyString: true,
      }),
    beneficiaryAddress2: string()
      .max(BEN_ADDRESS2_MAX_LENGTH, t.beneficiaryAddress2FormatMessage)
      .matches(BEN_ADDRESS_REGEXP, {
        message: t.beneficiaryAddress2FormatMessage,
        excludeEmptyString: true,
      }),
    beneficiaryCity: string()
      .required(t.beneficiaryCityRequiredMessage)
      .max(BEN_CITY_MAX_LENGTH, t.beneficiaryCityFormatMessage)
      .matches(BEN_CITY_REGEXP, {
        message: t.beneficiaryCityFormatMessage,
        excludeEmptyString: true,
      }),
    beneficiaryCountry: string().required(t.beneficiaryCountryRequiredMessage),
    beneficiaryEmailAddress: string()
      .required(t.beneficiaryEmailAddressRequiredMessage)
      .max(BEN_EMAIL_MAX_LENGTH, t.beneficiaryEmailAddressFormatMessage)
      .matches(BEN_EMAIL_REGEXP, {
        message: t.beneficiaryEmailAddressFormatMessage,
        excludeEmptyString: true,
      }),
    beneficiaryStateProvince: string()
      .required(t.beneficiaryStateProvinceRequiredMessage)
      .max(BEN_STATE_PROVINCE_MAX_LENGTH, t.beneficiaryStateProvinceFormatMessage)
      .matches(BEN_STATE_PROVINCE_REGEXP, {
        message: t.beneficiaryStateProvinceFormatMessage,
        excludeEmptyString: true,
      }),
    beneficiaryTaxID: string()
      .required(t.beneficiaryTaxIDRequiredMessage)
      .max(BEN_TAX_ID_MAX_LENGTH, t.beneficiaryTaxIDFormatMessage)
      .matches(BEN_TAX_ID_REGEXP, {
        message: t.beneficiaryTaxIDFormatMessage,
        excludeEmptyString: true,
      }),
    beneficiaryTelephoneNumber: string()
      .required(t.beneficiaryTelephoneNumberRequiredMessage)
      .max(BEN_PHONE_MAX_LENGTH, t.beneficiaryTelephoneNumberFormatMessage)
      .matches(BEN_PHONE_NUMBER_REGEXP, {
        message: t.beneficiaryTelephoneNumberFormatMessage,
        excludeEmptyString: true,
      }),
  };
}
