import { stringify } from 'query-string';
import { PortfolioSortOptions } from './pages/Portfolio/store/model';
import { OldAgreementType } from './pages/TermsAndConditions/model';

export const APP_BOOTSTRAP_ROOT = 'root';
export const API_PREFIX = 'umbraco/api/sosservices/';
export const API_FILE_PREFIX = 'umbraco/api/sosfileservices/';
export const SIGN_IN_POST_ACTION = 'umbraco/surface/loginsurface/login';

// Unread Messages and Alerts constants
export const MESSAGE_POLL_INTERVAL = 120000;
export const ALERT_POLL_INTERVAL = 120000;
export const UNREAD_MESSAGES_PORTAL_ID = 'unread-messages-counter';
export const UNREAD_ALERTS_PORTAL_ID = 'unread-alerts-counter';
export const HEADER_PORTAL_ID = 'header-portal';
export const FOOTER_PORTAL_ID = 'footer-portal';

const apiPrefixed = (url: string): string => `${API_PREFIX}${url}`;
const apiFilePrefixed = (url: string): string => `${API_FILE_PREFIX}${url}`;

export const APP_CONFIG = {
  api: {
    sessionExtend: 'umbraco/api/session/extend',
    sessionTimeout: 'umbraco/api/session/timeout',
    messages: {
      unreadAlertsList: apiPrefixed('user/alerts/unread'),
      unreadAlerts: apiPrefixed('user/alerts/unread/count'),
      unreadMessages: apiPrefixed('messages/messagecount'),
      accounts: apiPrefixed('messages/newMessage/accounts'),
      newMessage: apiPrefixed('messages/newMessage'),
    },
    parameters: (page: string) =>
      `umbraco/surface/reactpropertiessurface/getproperties?alias=${page}`,
    companyLogos: (companyId: string) =>
      `umbraco/surface/companylogossurface/getcompanylogo?companyId=${companyId}`,
    bankInfo: (countryId: string) =>
      `umbraco/surface/bankinformationsurface/getbankinfobycountryid?id=${countryId}`,
    bankInfoCountries: () => `umbraco/surface/bankinformationsurface/GetBankInfoCountries`,
    account: {
      bankAccounts: (accountNumber: number) =>
        apiPrefixed(`investment/bankAccounts/${accountNumber}`),
      registrationTypes: apiPrefixed('registrationtypes'),
      SSN: {
        checkSSN: apiPrefixed(`ssn/check`),
      },
      taxId: {
        checkTaxId: apiPrefixed(`taxId/check`),
      },
    },
    accountOverview: apiPrefixed('account/overview'),
    accountPortfolio: {
      load: (
        accountIndicator: string | 'all' | 'all-not-filtered',
        sortOptions?: PortfolioSortOptions,
      ) =>
        apiPrefixed(
          `portfolio/${accountIndicator}${sortOptions
            ? `?${stringify({
              direction: sortOptions.direction,
              orderBy: sortOptions.orderBy,
            })}`
            : ''
          }`,
        ),
    },
    accountProfile: {
      load: apiPrefixed('userprofile'),
      update: apiPrefixed('userprofile'),
      validateCurrentPassword: apiPrefixed('validatecurrentpassword'),
      validateUsername: apiPrefixed('IsValidNewUser'),
      checkUsername: (username: string) => apiPrefixed(`isusernameexist?username=${username}`),
      checkPassword: apiPrefixed(`passwordhistory`),
      securityQuestions: apiPrefixed('securityquestions'),
      checkEmailBatchPending: apiPrefixed('IsSaveEmailAddressBatchPending'),
      checkSeasonalAddressBatchPending: (accountNumber: string) =>
        apiPrefixed(`IsSeasonalAddressBatchPending/${accountNumber}`),
      checkPrimaryAddressBatchPending: (accountNumber: string) =>
        apiPrefixed(`IsPrimaryAddressBatchPending/${accountNumber}`),
      checkIsConfirmPrimaryAddressBatchPending: (accountNumber: string) =>
        apiPrefixed(`IsConfirmPrimaryAddressBatchPending/${accountNumber}`),
      getUserEmailAddresses: apiPrefixed('UserEmailAddresses'),
      saveEmailAddress: apiPrefixed('EmailAddress'),
    },
    accountAddress: {
      data: apiPrefixed('account/address'),
      preview: (id: string, form: string) => apiPrefixed(`account/${id}/address/${form}/preview`),
      change: (id: string, form: string) => apiPrefixed(`account/${id}/address/${form}`),
      confirm: (id: string) => apiPrefixed(`account/${id}/address/confirm`),
      countries: apiPrefixed('country'),
      usStates: apiPrefixed('state/us'),
      provinces: apiPrefixed('province'),
      format: apiPrefixed('address/format'),
      secondaryAddressGet: (accountNumber: string, issueNumber: string) =>
        apiPrefixed(`account/address/secondary/${accountNumber}/${issueNumber}`),
      secondaryAddressAdd: (accountNumber: string, issueNumber: string) =>
        apiPrefixed(`account/address/secondary/${accountNumber}/${issueNumber}`),
      secondaryAddressRemove: (accountNumber: string, issueNumber: string) =>
        apiPrefixed(`account/address/secondary/${accountNumber}/${issueNumber}`),
    },
    agreement: {
      get: apiPrefixed('users/agreements'),
      accept: apiPrefixed('users/agreements/sign'),
    },
    brokerInstructions: {
      data: apiPrefixed('brokerInstructions'),
      instruction: (accountNumber: string, issueNumber: string) =>
        apiPrefixed(`brokerInstructions/${accountNumber}/${issueNumber}`),
    },
    checks: {
      overview: apiPrefixed('checks/overview'),
      history: apiPrefixed('checks/history'),
      downloadHistory: apiFilePrefixed(`checks/history/download`),
      replace: (accountNumber: string) => apiPrefixed(`checks/replace/${accountNumber}`),
      submit: apiPrefixed('checks/replace'),
      filter: {
        companies: apiPrefixed('checks/filter/companies'),
        reasons: apiPrefixed('checks/filter/paymentreasons'),
        statuses: apiPrefixed('checks/filter/paymentstatuses'),
      },
    },
    espp: {
      management: apiPrefixed('espp/employees/management'),
      companies: apiPrefixed('espp/companies'),
      companyDetails: (id: number) => apiPrefixed(`espp/companies/${id}/details`),
      companyAuthentication: (id: number) => apiPrefixed(`espp/companies/${id}/authentication`),
      currentEmployee: (issueId: number) => apiPrefixed(`espp/employees?${stringify({ issueId })}`),
      currentWfEmployee: () => apiPrefixed(`espp/wf/employees`),
      validations: apiPrefixed(`espp/employees/validations`),
      newValidations: {
        secure: apiPrefixed(`espp/employees/secure-validate`),
        mfa: apiPrefixed(`espp/employees/mfa-validate`),
      },
      getPlan: (companyId: number, taxId: string | undefined, employeeNumber: string | undefined) =>
        apiPrefixed(`espp/companies/${companyId}/plans?${stringify({ taxId, employeeNumber })}`),
      downloadInvestmentMaterial: (fileId: string, fileName: string) =>
        apiFilePrefixed(`File?fileId=${fileId}&filename=${fileName}`),
      getDeductions: () => apiPrefixed(`espp/deductions`),
      deductions: (
        issueId: number,
        employeeNumber: string | undefined,
        taxId: string | undefined,
      ) => apiPrefixed(`espp/deductions?${stringify({ issueId, employeeNumber, taxId })}`),
    },
    contactQuestions: apiPrefixed('contactQuestions'),
    documents: {
      accounts: apiPrefixed('documents/accounts'),
      eDelivery: (accountNumber: number) => apiPrefixed(`documents/edelivery/${accountNumber}`),
      download: (accountNumber: number, statementId: number, issueNumber: string) =>
        apiFilePrefixed(`documents/download/${accountNumber}/${statementId}/${issueNumber}`),
      downloadInsert: (accountNumber: number, statementId: number, issueNumber: string) =>
        apiFilePrefixed(`documents/downloadInserts/${accountNumber}/${statementId}/${issueNumber}`),
      taxForms: (accountNumber: number) => apiPrefixed(`documents/taxforms/${accountNumber}`),
      shareBalance: {
        accounts: apiPrefixed('shareBalance/accounts'),
        download: apiPrefixed(`shareBalance/download`),
      },
    },
    deliveryPreferences: {
      load: apiPrefixed('edelivery/preferences'),
      update: apiPrefixed('edelivery/preferences'),
      receivePreferences: apiPrefixed('edelivery/receive/preferences'),
    },
    dividends: {
      loadList: apiPrefixed('dividends'),
      loadDripDetails: (issueNumber: string, accountNumber: number) =>
        apiPrefixed(`dripdetails/accountNumber/${accountNumber}/issuenumber/${issueNumber}`),
      loadSetupDetails: (userRegionCode: string) =>
        apiPrefixed(`internationalachsetupdetails/userregioncode/${userRegionCode}`),
      validatePaymentFieldFormat: () => apiPrefixed(`dripdetails/validateandpadd`),
      update: (issueNumber: string, accountNumber: number) =>
        apiPrefixed(`dripdetails/accountNumber/${accountNumber}/issuenumber/${issueNumber}`),
      brochure: (brochureLink: string) =>
        apiFilePrefixed(`File?fileId=${brochureLink}&filename=Brochure`),
      secondaryAddressGet: (accountNumber: string, issueNumber: string) =>
        apiPrefixed(`account/address/secondary/${accountNumber}/${issueNumber}`),
      secondaryAddressAdd: (accountNumber: string, issueNumber: string) =>
        apiPrefixed(`account/address/secondary/${accountNumber}/${issueNumber}`),
      secondaryAddressRemove: (accountNumber: string, issueNumber: string) =>
        apiPrefixed(`account/address/secondary/${accountNumber}/${issueNumber}`),
      downloadSaleForm: (accountNumber: number, issueNumber: string) =>
        apiFilePrefixed(`saleForms/download/${accountNumber}/${issueNumber}`),
    },
    proxyDeliveryPreferences: {
      load: apiPrefixed('proxydelivery/preferences'),
      update: apiPrefixed('proxydelivery/preferences'),
    },
    taxCertification: {
      load: apiPrefixed('account/taxcertification'),
      countries: apiPrefixed('country'),
      validatetaxcertification: (accountNumber: number) =>
        apiPrefixed(`account/validatetaxcertification/${accountNumber}`),
      save: (accountNumber: number) =>
        apiPrefixed(`account/updatetaxidcertificationdetails/${accountNumber}`),
      certify: (accountNumber: number | string) =>
        apiPrefixed(`account/taina/taxcertification/${accountNumber}`),
    },
    securityOverview: {
      transaction: {
        getInquiryTime: () => apiPrefixed('transactions/inquiry-time'),
        getTransactions: (
          accountNumber: string,
          issueNumber: string,
          type?: 'drs' | 'certificate',
        ) => {
          return type
            ? apiPrefixed(`account/transactions/${accountNumber}/${issueNumber}/${type}`)
            : apiPrefixed(`account/transactions/${accountNumber}/${issueNumber}`);
        },
        downloadTransactions: (accountNumber: string, issueNumber: string) =>
          apiFilePrefixed(`account/transactions/download/${accountNumber}/${issueNumber}`),
        getInfo: (accountNumber: string, issueNumber: string) =>
          apiPrefixed(`account/transactionsinfo/${accountNumber}/${issueNumber}`),
        getPlans: (accountNumber: string, issueNumber: string) =>
          apiPrefixed(`planshares/${accountNumber}/${issueNumber}`),
        getPlansInfo: (accountNumber: string, issueNumber: string) =>
          apiPrefixed(`planshares/${accountNumber}/${issueNumber}/info`),
        downloadPlans: (accountNumber: string, issueNumber: string) =>
          apiFilePrefixed(`PlanShares/${accountNumber}/${issueNumber}/download`),
      },
      details: (accountNumber: string, issueNumber: string) =>
        apiPrefixed(`account/securitydetails/${accountNumber}/${issueNumber}`),
      sales: {
        getSales: (accountNumber: number, issueNumber: string) =>
          apiPrefixed(`account/sales/${accountNumber}/${issueNumber}`),
        getOldestSale: (accountNumber: number, issueNumber: string) =>
          apiPrefixed(`account/sales/oldest/${accountNumber}/${issueNumber}`),
        getPendingSales: apiPrefixed(`account/sales/pending`),
        getPendingSale: (saleId: number) => apiPrefixed(`account/sales/pending/${saleId}`),
        cancelPendingSale: (accountNumber: number, saleId: number) =>
          apiPrefixed(`account/sales/pending/${accountNumber}/${saleId}`),
        download: (accountNumber: string, issueNumber: string) =>
          apiFilePrefixed(`account/sales/${accountNumber}/${issueNumber}/download`),
      },
      saleProceeds: {
        getList: (accountNumber: string, issueNumber: string) =>
          apiPrefixed(`accounts/saleProceeds/${accountNumber}/${issueNumber}`),
        getSummary: (accountNumber: string, issueNumber: string) =>
          apiPrefixed(`accounts/saleProceedsSummary/${accountNumber}/${issueNumber}`),
        download: (accountNumber: string, issueNumber: string) =>
          apiFilePrefixed(`accounts/saleProceeds/${accountNumber}/${issueNumber}/download`),
      },
      dividendProceeds: {
        getList: (accountNumber: string, issueNumber: string) =>
          apiPrefixed(`account/${accountNumber}/issues/${issueNumber}/dividend-proceeds`),
        getSummary: (accountNumber: string, issueNumber: string) =>
          apiPrefixed(`account/${accountNumber}/issues/${issueNumber}/dividend-proceeds-summary`),
        download: (accountNumber: string, issueNumber: string) =>
          apiFilePrefixed(`account/dividendProceeds/${accountNumber}/${issueNumber}/download`),
      },
      costBasis: {
        summary: (accountNumber: string, issueNumber: string) =>
          apiPrefixed(`account/costBasis/${accountNumber}/${issueNumber}/summary`),
        taxLots: (accountNumber: string, issueNumber: string) =>
          apiPrefixed(`account/costBasis/${accountNumber}/${issueNumber}/taxLots`),
        taxLotDetails: (accountNumber: string, issueNumber: string, taxLotId: number) =>
          apiPrefixed(`account/costBasis/${accountNumber}/${issueNumber}/taxLots/${taxLotId}`),
        debitDetails: (accountNumber: string, issueNumber: string, taxLotId: number) =>
          apiPrefixed(
            `account/costBasis/${accountNumber}/${issueNumber}/taxLots/${taxLotId}/debitDetails`,
          ),
        download: (accountNumber: string, issueNumber: string) =>
          apiFilePrefixed(`account/costBasis/${accountNumber}/${issueNumber}/download`),
      },
    },
    inbox: {
      messages: apiPrefixed('messages'),
      companyMessages: apiPrefixed('company-messages'),
      deleteMessages: (messageIds: number[]) => apiPrefixed(`messages/${messageIds}`),
      updateReadStatus: apiPrefixed('messages/updateReadStatus'),
      attachments: (messageId: number) => apiPrefixed(`messages/${messageId}/attachments`),
      attachmentLink: (messageId: number, id: number) =>
        apiFilePrefixed(`messages/${messageId}/attachment//${id}`),
      reply: apiPrefixed('messages/reply'),
    },
    companies: {
      all: apiPrefixed('companies'),
    },
    investment: {
      registrationPreview: apiPrefixed('investment/registrationPreview'),
      issueFeeAmount: (issueNumber: string, regionCode: string) =>
        apiPrefixed(`investment/issuefeeamount/${issueNumber}/${regionCode}`),
      companyDeliveryOptions: (issueNumber: string) =>
        apiPrefixed(`investment/companydeliveryoptions/${issueNumber}`),
      paymentCompany: apiPrefixed('investment/payment-company'), // todo: check with backend ??
      companySecurityTypes: apiPrefixed('investment/companysecuritytypes'),
      automaticInvestment: (accountNumber: string, issueNumber: string) =>
        apiPrefixed(`investment/automaticinvestment/${accountNumber}/${issueNumber}`),
      automaticInvestmentBatch: (accountNumber: string, issueNumber: string) =>
        apiPrefixed(
          `investment/CheckForBatchedAutomaticInvestment/${accountNumber}/${issueNumber}`,
        ),
      investmentPreferences: (issueNumber: string, accountNumber?: string) =>
        apiPrefixed(`investment/preferences/${issueNumber}/${accountNumber ? accountNumber : 0}`),
      companyPlan: (issueNumber: string, userRegionCode: string) =>
        apiPrefixed(`investment/issuefeeamount/${issueNumber}/${userRegionCode}`),
      companyPlanBrochure: (issueId: string, planType: string) =>
        apiPrefixed(`investment/plandocuments/${issueId}/${planType}`),
      companyPlanBrochureLink: (brochureLink: string, brochureName: string) =>
        apiFilePrefixed(`File?fileId=${brochureLink}&filename=${brochureName}`),
      accountVerify: (accountNumber: string, issueNumber: string) =>
        apiPrefixed(`investment/registrationVerificationDetails/${accountNumber}/${issueNumber}`),
      submit: apiPrefixed(`investment/automaticinvestment`),
      submitNoStock: apiPrefixed(`investment/automaticinvestmentdpp`),
      stop: apiPrefixed(`investment/stopautomaticinvestment`),
    },
    signUp: {
      companies: apiPrefixed('companies/signup'),
      authIdRecovery: () => apiPrefixed(`account/forgot/accountid`),
      authenticate: (type: 'employee' | 'authId') => apiPrefixed(`signup/${type}/authentication`),
      agreement: (type: OldAgreementType) => apiPrefixed(`signup/agreements/${type}`),
      byAuthId: (authId: string) => apiPrefixed(`signup/authId/${authId}/profile`),
      byEmployeeNumberCompanyId: (employeeNumber: string, companyId: string) =>
        apiPrefixed(`signup/employee/${employeeNumber}/company/${companyId}/profile`),
      byWfSso: () => apiPrefixed(`signup/wf/employee/profile`),
    },
    signOn: {
      signOut: `umbraco/surface/signoutsurface/SignOut`,
      unlockAccount: apiPrefixed('account/unlock'),
      recoverAccount: {
        email: apiPrefixed('recoverAccount/account'),
        validateToken: apiPrefixed('recoverAccount'),
        username: apiPrefixed('recoverAccount'),
        credentials: apiPrefixed('recoverAccount/reset'),
      },
      recoverUsername: {
        byAuthenticationId: apiPrefixed('account/recoverUsername/authenticationId'),
        byAccountNumber: apiPrefixed('account/recoverUsername/accountNumber'),
        byEmployeeNumber: apiPrefixed('account/recoverUsername/employeeNumber'),
        confirmSecurityAnswerWithAuthenticationId: apiPrefixed(
          'account/recoverUsername/securityQuestion/authenticationId',
        ),
        confirmSecurityAnswerWithAccountNumber: apiPrefixed(
          'account/recoverUsername/securityQuestion/accountNumber',
        ),
        confirmSecurityAnswerWithEmployeeNumber: apiPrefixed(
          'account/recoverUsername/securityQuestion/employeeNumber',
        ),
      },
      securityQuestion: {
        validate: apiPrefixed('securityQuestions/validation'),
        reset: apiPrefixed('securityQuestions/reset'),
      },
      passwordReset: {
        validate: {
          account: apiPrefixed('account/resetPassword/accountNumber'),
          employee: apiPrefixed('account/resetPassword/employeeNumber'),
          authId: apiPrefixed('account/resetPassword/authenticationId'),
        },
        answer: {
          account: apiPrefixed('account/resetPassword/securityQuestion/accountNumber'),
          employee: apiPrefixed('account/resetPassword/securityQuestion/employeeNumber'),
          authId: apiPrefixed('account/resetPassword/securityQuestion/authenticationId'),
        },
        password: {
          account: apiPrefixed('account/resetPassword/newPassword/accountNumber'),
          employee: apiPrefixed('account/resetPassword/newPassword/employeeNumber'),
          authId: apiPrefixed('account/resetPassword/newPassword/authenticationId'),
        },
      },
    },
    shares: {
      shared: {
        wireTransferCountryConfig: apiPrefixed('sharesSales/wireTransfer/configuration'),
      },
      sell: {
        accounts: apiPrefixed('accounts/sharesdetails'),
        account: (accountNumber: number) => apiPrefixed(`accounts/sharesdetails/${accountNumber}`),
        saleOrderDetails: apiPrefixed(`sharesSales/saleOrderDetails`),
        shares: apiPrefixed(`sharesSales/sharesToSell`),
      },
    },
    company: {
      plans: apiPrefixed('company/plans'),
      pagedPlans: apiPrefixed('company/plans/paged'),
      details: (issueNumber: string, planType: string) =>
        apiPrefixed(`company/plan/details/${issueNumber}/${planType}`),
      document: (id: string, name: string) => apiFilePrefixed(`File?fileId=${id}&filename=${name}`),
    },
    contact: {
      questions: apiPrefixed('contactQuestions'),
      submitForm: apiPrefixed('contactUs'),
    },
    termsAndConditions: {
      userAgreements: 'umbraco/surface/usersurface/userAgreements',
      acceptESignAgreement: apiPrefixed('account/termsAndConditions/esign'),
      acceptOnlineAccessAgreement: apiPrefixed('account/termsAndConditions/onlineaccess'),
    },
    emailMismatch: {
      userEmailMismatch: 'umbraco/surface/usersurface/userEmailMismatch',
    },
    user: {
      current: 'umbraco/surface/usersurface/currentUser',
    },
    userPreferences: {
      get: apiPrefixed('user/preferences'),
      post: apiPrefixed('user/preferences'),
    },
    routes: `umbraco/api/routing`,
  },
  displayFormat: 'd MMMM',
};
