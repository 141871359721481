import { ApiValidationError } from '../../../lib/Form/Validation/validation.model';
import { BankAccount, PaymentMethodType } from '../../../shared/DataStructures';
import { GetActionTypes } from '../../../store/reduxBoilerplate';
import { ProgressTypeEnum } from '../../../store/rootModel';
import { dividendActions, dividendSecondaryAddressActions } from './dividend.actions';
import { NewBankAccount } from '../../Shares/Sell/models';
import { BeneficiaryDetails } from '../../Shares/Sell/Steps/ConfirmationStep/api.models';

export type DividendAction = GetActionTypes<
  typeof dividendActions & typeof dividendSecondaryAddressActions
>;
export type GetDividendAction<T extends keyof typeof dividendActions> = ReturnType<
  typeof dividendActions[T]
>;

export type DividendAddressAction = GetActionTypes<typeof dividendSecondaryAddressActions>;
export type GetDividendAddressAction<
  T extends keyof typeof dividendSecondaryAddressActions
> = ReturnType<typeof dividendSecondaryAddressActions[T]>;

export interface DividendsState {
  readonly updateProgressType?: ProgressTypeEnum;
  readonly dividendDetails?: DividendDripDetails;
  readonly activeStep: DividendStep;
  readonly loadingSetupDetails?: ProgressTypeEnum;
  readonly setupDetails?: InternationalACHSetupDetails[];
  readonly loadingWireTransferCountries?: ProgressTypeEnum;
  readonly wireTransferCountries?: Country[];
  readonly loadingWireTransferCurrencies?: ProgressTypeEnum;
  readonly wireTransferCurrencies?: Currency[];
  readonly externallyValidatedPaymentFields: ExternallyValidatedPaymentFields;
  readonly persistedFormsValidStatusMap?: { [key in PersistedDividendForm]?: boolean };
  readonly secondaryAddressPreview: string[];
  readonly errors?: ApiValidationError[];
  readonly secondaryAddressInquiryMode?: boolean;
  readonly dividendsUpdateInquiryMode?: boolean;
  readonly skippedPaymentStep?: boolean;
}

export interface Step {
  key: DividendStep;
  label?: string;
}

export enum DividendStep {
  SelectCompany = 'SelectCompany',
  Details = 'Details',
  Plan = 'Plan',
  Payment = 'Payment',
  Confirm = 'Confirm',
  Summary = 'Summary',
}

export enum PersistedDividendForm {
  SelectCompany = 'SelectCompany',
  Details = 'DividendDetails',
  Plan = 'DividendPlan',
  Payment = 'DividendPayment',
}

export enum PartialReinvestmentType {
  Percentage = 'Percentage',
  Shares = 'Shares',
  Cash = 'Cash',
  Reinvest = 'Reinvest',
}

export interface DividendDripDetails {
  companyName: string;
  accountNumber: number;
  userRegionCode: string;
  securityType: string;
  currentPaymentMethod: PaymentMethod;
  currentReinvestmentOption: DividendReinvestment;
  reinvestmentPercent: number;
  reinvestmentValue: number;
  paymentBrochureLink: string;
  withdrawalTerminationIndicator: boolean;
  allowCreditACHForDividends: boolean;
  allowWireTransfer: boolean;
  allowDrip: boolean;
  allowDRIPEnrollmentOnline: boolean;
  reinvestmentOptionsAvailable: boolean;
  checkAllowedSOL: boolean;
  numberOfPlanShares: number;
  numberOfDRSShares: number;
  numberOfCertificateShares: number;
  reinvestmentOptions: ReinvestmentOption[];
  issuedUnissuedIndicators: IssuedUnissuedIndicator[];
  paymentOptions?: PaymentOption[] | null; // null value comes from backend, when DD and WT disabled, and Only Check payment. But it's checkAllowedSOL flag for.
  currentBankAccount: BankAccount;
  usBankAccounts?: BankAccount[];
  dividendPaying: boolean;
  dividendRate?: string;
  netDividendProceeds?: number;
}

export interface InternationalACHSetupDetails {
  accountNumberFormat: string;
  bankCodeFormat: string;
  country: Country;
  iBAN: string;
  currency: string;
  regionCode: string;
}

export interface Currency {
  code: string;
  name: string;
}

export interface ReinvestmentOption {
  reinvestmentType: ReinvestmentType;
  fieldName: string;
}

export interface IssuedUnissuedIndicator {
  indicator: string;
  indicatorValue: 'Y' | 'N';
}

export interface PaymentOption {
  paymentOption: PaymentMethod;
  paymentOptionType: PaymentMethodType;
}

export interface Country {
  code: string;
  name: string;
  irsCountryCode: string;
  isPostalCodeMandatory: boolean;
}

export enum DividendReinvestment {
  RD = 'RD',
  RX = 'RX',
  RP = 'RP',
  RP0 = 'RP0',
  PS = 'PS',
  RV = 'RV',
  RVY = 'RVY',
  RVN = 'RVN',
  PD = 'PD',
}

export enum PaymentMethod {
  Check = 'Check',
  WireTransfer = 'Wire Transfer',
  DirectDeposit = 'Direct Deposit',
  None = 'None',
  Both = 'Both',
  CurrentPaymentC = 'C',
  CurrentPaymentH = 'H',
  CurrentPaymentT = 'T',
}

/* Codes Description 
PD: If dividend paying Issue, Dividend will be paid in cash by check/ACH/wire transfer.  (Not a participant in a plan.) 
RP0: All certificated, DRS shares and/or Plan shares will be paid in cash. (no reinvestment) 
RD: All certificated, DRS shares and/or Plan shares are reinvested – full reinvestment, there is no payment option as all shares will be reinvested 
RXY: User specified percentage of certificated and/or DRS shares will be reinvested, the remaining certificated and/or DRS shares will be paid in cash.  All of the Plan shares will reinvest. 
RXN: User specified percentage of certificated, DRS shares and/or Plan shares will be reinvested.  The remaining shares will be paid in cash. 
RPY: User specified number of certificated and/or DRS shares will be reinvested, the remaining certificated and/or DRS shares will be paid in cash.  All of the Plan shares will reinvest. 
RPN: User specified number of certificated, DRS shares and/or Plan shares will be reinvested.  The remaining shares will be paid in cash. 
PSY: User specified number of certificated and/or DRS shares will be paid in cash, the remaining certificated and/or DRS shares will be reinvested.  All of the Plan shares will reinvest. 
PSN: User specified number of certificated, DRS shares and/or Plan shares will be paid in cash.  The remaining shares will be reinvested. 
RVY: All certificated and/or DRS shares will be paid. All plan shares will reinvest. User can optionally specify one off purchases under this plan option, i.e. optional cash. 
RVN: All certificated, DRS shares and/or Plan shares will be paid. User can optionally specify one off purchases under this plan option, i.e. optional cash. 
*/
export enum ReinvestmentType {
  RD = 'RD', // Full reinvestment
  PD = 'PD',
  RXN = 'RXN',
  RXY = 'RXY',
  RPN = 'RPN',
  RPY = 'RPY',
  PSN = 'PSN',
  PSY = 'PSY',
  RVY = 'RVY',
  RVN = 'RVN',
  RP0 = 'RP0', // Optional cash with no reinvestment
}

export type ExternallyValidatedPaymentFields = {
  [X in ExternallyValidatedPaymentField]?: ExternallyValidatedPaymentFieldConfig;
};

export interface ExternallyValidatedPaymentFieldConfig {
  valid?: boolean;
  loading?: ProgressTypeEnum;
}

export enum ExternallyValidatedPaymentField {
  NewDirectDepositINTBankAccountNumber = 'newDirectDepositINTBankAccountNumber',
  NewDirectDepositINTIBAN = 'newDirectDepositINTIBAN',
  NewDirectDepositINTRoutingNumber = 'newDirectDepositINTRoutingNumber',
  NewDirectDepositINTSwiftBicCode = 'newDirectDepositINTSwiftBicCode',
}

export interface DividendCardProps {
  plan: DividendCardPlanProps;
}

interface DividendCardPlanProps {
  accountNumber: string;
  securityType: string;
  paymentMethod: PaymentMethod;
  dividendReinvestment: DividendReinvestment;
  paymentBrochureLink: string;
}

export type LoadDividendDetailsResponse = DividendDripDetails;

export type LoadSetupDetailsResponse = InternationalACHSetupDetails[];

export interface ValidatePaymentFieldFormatRequest {
  input: string;
  format: string;
}

export interface ValidatePaymentFieldFormatResponse {
  valid: boolean;
  paddedValue?: string;
}

export interface UpdateDividendRequest {
  planUpdate?: PlanUpdate | null;
  paymentUpdate?: PaymentUpdate | null;
}

export interface PlanUpdate {
  reinvestmentOption?: DividendReinvestment;
  reinvestmentPercent?: string;
  reinvestmentValue?: string;
  quantityToCash?: string;
}

export interface PaymentUpdate {
  dividendPaymentOption: PaymentOption;
  isNewBankAccount: boolean;
  bankAccount?: Partial<BankAccount>;
  dividendBankAccount?: Partial<NewBankAccount>;
  beneficiaryBankSwiftCode?: string;
  internationalRoutingCode?: string;
  beneficiaryDetails?: BeneficiaryDetails;
}

export interface NewSecondaryAddressDetails {
  addressLines: string[];
  countryCode: string;
  city: string;
  state: string | null;
  zipCode: {
    primary: string;
    optional: string | null;
  };
}
