import { BankAccount, BankAccountType } from '../../../../shared/DataStructures/BankAccount';
import { ShareTransaction } from '../Steps/SaleOrderDetailsStep/SaleOrderDetails.model';
import { InternationalACHSetupDetails } from '../../../Dividend/store/dividend.model';
import {
  DividendCmsData,
  SellSharesCmsData,
  SellSharesTranslate,
  SellSharesTranslateHtml,
  TranslateErrors,
} from '../../../../containers/CmsData';
import { WireTransferPayment } from '../../../../lib/FormPremades/WireTransferPayment';

export type SellTranslations = SellSharesCmsData & DividendCmsData;

export interface StepPropsBase {
  onCancel: () => void;
  navigateNext: (data?: Partial<SellJourney>) => void;
  navigateBack?: () => void;
}
export type StepProps<P> = StepPropsBase & P;
export interface TranslateProps {
  _t: SellSharesTranslate;
}

export interface TranslateHtmlProps {
  _thtml: SellSharesTranslateHtml;
}

export interface TranslateErrorsProps {
  _eMany: TranslateErrors;
}

export interface SellSharesDetails {
  accountCompanies: SellAccount[];
  isMarketPricingAvailable: boolean;
}

export interface SellAccount {
  accountNumber: number;
  name: string;
  value: number;
  companies: SellCompany[];
  taxCertificationStatus: number;
  primaryAddress: AccountAddress;
  seasonalAddress?: AccountAddress;
  address: string;
  userRegionCode: string;
}

export interface AccountAddress {
  addressLines: string[] | null;
  start?: Date | string;
  end?: Date | string;
  isRecurring?: boolean;
  isActive?: boolean;
}

export interface SellCompany {
  companyId: number;
  name: string;
  shares: SellShare[];
}

export interface SellShare {
  drsAvailableForSale: number;
  planAvailableForSale: number;
  planTotalWithReserved: number;
  certificateAvailableForSale: number;
  securityType: string;
  issueNumber: string;
  drsTotalWithReserved: number;
  drsNumberOfShares: number;
  planNumberOfShares: number;
  allowSaleOfDrs: boolean;
  allowDrsSalesSol: boolean;
  allowSaleOfPlanShares: boolean;
  allowPlanSalesSol: boolean;
  drsFractionalSaleAllowed: boolean;
  planFractionalSaleAllowed: boolean;
  allowSaleReceiptsByACH: boolean;
  allowSaleReceiptsByInternationalACH: boolean;
  allowSaleReceiptsByWireTransfer: boolean;
  salePaymentType: SalePaymentType;
  sellAllPlanSharesTerminatesAccount: boolean;
}

export enum SalePaymentType {
  WireTransfer = 'WireTransfer',
  DirectDeposit = 'DirectDeposit',
  Check = 'Check',
  Both = 'Both',
}

export enum PaymentMethod {
  WireTransfer = 'WireTransfer',
  DirectDeposit = 'DirectDeposit',
  Check = 'Check',
  None = 'None',
}

export enum AccountLocation {
  US = 'us',
  INTL = 'intl',
}

export enum ExistingOrNew {
  Existing = 'existing',
  New = 'new',
}

export enum ShareType {
  None = '',
  DrsShare = '0',
  PlanShare = '1',
  DrsAndPlanShare = '2',
}

export enum SellMethod {
  FIFO = '0',
  DATE = '1',
}

export enum SaleType {
  MarketOrder = 'MarketOrder',
  BatchOrder = 'BatchOrder',
  LimitOrder = 'LimitOrder',
  StopLossOrder = 'StopLossOrder',
}

//#region Bank details
export interface BankDetails {
  payoutType: PaymentMethod;
  accountToUse: ExistingOrNew;
  existingBankAccountNumber: string;
  isDomesticBank: boolean;
  newBankAccount: NewBankAccount;
  wireTransfer: WireTransferPayment;
}

export interface NewBankAccount {
  accountNumber: string;
  bankName: string;
  bankCity: string;
  routingNumber: string;
  accountType: BankAccountType;
  countryCode: string;
  country: string;
  currency: string;
}

export const initialNewBankAccount: NewBankAccount = {
  currency: '',
  routingNumber: '',
  countryCode: '',
  bankName: '',
  bankCity: '',
  accountType: BankAccountType.None,
  accountNumber: '',
  country: '',
};
export const initialBankDetails: BankDetails = {
  payoutType: PaymentMethod.None,
  accountToUse: ExistingOrNew.Existing,
  existingBankAccountNumber: '',
  isDomesticBank: true,
  newBankAccount: initialNewBankAccount,
  wireTransfer: {} as WireTransferPayment,
};
//#endregion

export interface BookLotSaleInfo {
  number: string;
  amountToSell: number;
  sellAll: boolean;
  isSelling: boolean;
}

export interface NormalizedList<TEntity> {
  [key: string]: TEntity;
}

export interface SellJourney extends BankDetails, AutoSellConfig {
  accountNumber: number;
  companyId: number;
  securityType: string;
  issueNumber: string;
  agreesToTerms: boolean;
  saleType: SaleType;
  shareTypesToSell: ShareType;
  drsSellAmount: number;
  planSellAmount: number;
  saleMethod: SellMethod;
  selectedBankAccount?: BankAccount;
  bookLotSaleConfig: NormalizedList<BookLotSaleInfo>;
}

export interface SellTypesConfiguration {
  saleTypesDetails: SellType[];
  commissionPerPlanShare: Fee;
  commissionPerDrsShare: Fee;
  planDirectDepositFees: Fee;
  drsDirectDepositFees: Fee;
  planWireTransferFees: Fee;
  drsWireTransferFees: Fee;
  planSalesDollarLimit: number;
  drsSalesDollarLimit: number;
  marketInfo: MarketInfo;
  bookLots: ShareTransaction[];
  restrictionPriceVariance: number;
  limitOrderAutoSellLimit: number;
  stopLossOrderAutoSellLimit: number;
  isMarketPricingAvailable: boolean;
  hasCancellableSaleOrders: boolean;
  price: number;
  priceType: PriceType;
}

export enum PriceType {
  Current = 'Current',
  PreviousDate = 'PreviousDate',
  FaceValue = 'FaceValue',
}

export interface Fee {
  amount: number;
  type: FeeType;
  paidBy?: PaidBy;
  discount?: number;
}

export interface MarketInfo {
  isHoliday: boolean;
  isMarketClosed: boolean;
  isMarketClosing: boolean;
  isLimitClosing: boolean;
}

export enum FeeType {
  Amount = 'Amount',
  Percentage = 'Percentage',
}

export enum PaidBy {
  Shareowner = 'Shareowner',
  Company = 'Company',
}

//#region AutoSell
export enum AutoSellType {
  None = '0',
  ForTheDay = '1',
  UntilCancelled = '2',
  UntilDate = '3',
}

export interface AutoSellConfig {
  planAutoSellType: AutoSellType;
  planAutoSellPrice?: number;
  planAutoSellDate?: Date;

  drsAutoSellType: AutoSellType;
  drsAutoSellPrice?: number;
  drsAutoSellDate?: Date;
}

//#endregion

export interface SellType {
  saleType: string;
  timing: string;
  planFees: Fee;
  drsFees: Fee;
}

export interface SellJourneyMetaData {
  accounts: SellAccount[];
  selectedAccount?: SellAccount;
  selectedCompany?: SellCompany;
  selectedIssue?: SellShare;
  sellTypes?: SellTypesConfiguration;
  internationalCurrency?: string;
  sharePrice?: number;
  countries: InternationalACHSetupDetails[];
  isMarketPricingAvailable: boolean;
  inquiryMode: boolean;
  totalSharesToSell: string;
  bankAccounts?: BankAccount[];
  wireTransferCountry?: string;
  getBookLots: (autoSellPrice?: number) => ShareTransaction[];
}

export const autoSellConfigInitialValues: AutoSellConfig = {
  planAutoSellType: AutoSellType.None,
  planAutoSellPrice: undefined,
  planAutoSellDate: undefined,

  drsAutoSellType: AutoSellType.None,
  drsAutoSellPrice: undefined,
  drsAutoSellDate: undefined,
};
