import MockAdapter from 'axios-mock-adapter';
import { ApiResponse } from '../../../../lib/Form/Validation/validation.model';
import { APP_CONFIG } from '../../../../config';
import { SalePaymentType, SellSharesDetails } from '../models/SellSharesTypes';

export const RESPONSE: ApiResponse<SellSharesDetails> = {
  success: true,
  data: {
    isMarketPricingAvailable: true,
    accountCompanies: [
      {
        accountNumber: 4002250777,
        name: 'IRIS,ACCOUNT847',
        value: 15852690.726019999,
        taxCertificationStatus: -1,
        primaryAddress: { addressLines: ['TEST', 'TEST MN 55406', '', '', ''] },
        seasonalAddress: {
          addressLines: ['TESTA', 'TESTB TESTC TESTD', 'ALBANIA', '', ''],
          start: '2019-05-02T00:00:00',
          end: '2019-05-03T00:00:00',
          isRecurring: true,
        },
        address: 'address',
        userRegionCode: '050',
        companies: [
          {
            name: 'GMR Group',
            companyId: 2315,
            shares: [
              {
                drsTotalWithReserved: 99999.999,
                planTotalWithReserved: 98888.889,
                drsAvailableForSale: 42440.534,
                planAvailableForSale: 1886.793,
                certificateAvailableForSale: 0.0,
                securityType: 'COMMON',
                issueNumber: 'DDD1',
                drsNumberOfShares: 44175.661,
                planNumberOfShares: 1886.793,
                allowSaleOfDrs: true,
                allowDrsSalesSol: true,
                allowSaleOfPlanShares: false,
                allowPlanSalesSol: false,
                drsFractionalSaleAllowed: true,
                planFractionalSaleAllowed: true,
                allowSaleReceiptsByACH: true,
                allowSaleReceiptsByInternationalACH: true,
                allowSaleReceiptsByWireTransfer: false,
                salePaymentType: SalePaymentType.Both,
                sellAllPlanSharesTerminatesAccount: true,
              },
              {
                drsTotalWithReserved: 99999.999,
                planTotalWithReserved: 98888.889,
                drsAvailableForSale: 42440.534,
                planAvailableForSale: 1886.793,
                certificateAvailableForSale: 0.0,
                securityType: 'DIVIDEND',
                issueNumber: 'DDD2',
                drsNumberOfShares: 44175.661,
                planNumberOfShares: 1886.793,
                allowSaleOfDrs: false,
                allowDrsSalesSol: false,
                allowSaleOfPlanShares: false,
                allowPlanSalesSol: false,
                drsFractionalSaleAllowed: false,
                planFractionalSaleAllowed: false,
                allowSaleReceiptsByACH: true,
                allowSaleReceiptsByInternationalACH: false,
                allowSaleReceiptsByWireTransfer: true,
                salePaymentType: SalePaymentType.Both,
                sellAllPlanSharesTerminatesAccount: true,
              },
            ],
          },
          {
            name: 'ADR India',
            companyId: 2298,
            shares: [
              {
                drsTotalWithReserved: 99999.999,
                planTotalWithReserved: 98888.889,
                drsAvailableForSale: 614781.92,
                planAvailableForSale: 128428.35,
                certificateAvailableForSale: 0.0,
                securityType: 'COMMON',
                issueNumber: 'IND1',
                drsNumberOfShares: 616781.92,
                planNumberOfShares: 128428.35,
                allowSaleOfDrs: true,
                allowDrsSalesSol: true,
                allowSaleOfPlanShares: true,
                allowPlanSalesSol: true,
                drsFractionalSaleAllowed: false,
                planFractionalSaleAllowed: false,
                allowSaleReceiptsByACH: true,
                allowSaleReceiptsByInternationalACH: false,
                allowSaleReceiptsByWireTransfer: true,
                salePaymentType: SalePaymentType.Both,
                sellAllPlanSharesTerminatesAccount: true,
              },
            ],
          },
          {
            name: 'MUMBAI INDIAN',
            companyId: 2299,
            shares: [
              {
                drsTotalWithReserved: 99999.999,
                planTotalWithReserved: 98888.889,
                drsAvailableForSale: 43891.082,
                planAvailableForSale: 7857.558,
                certificateAvailableForSale: 0.0,
                securityType: 'COMMON',
                issueNumber: 'MUM1',
                drsNumberOfShares: 43891.082,
                planNumberOfShares: 7857.558,
                allowSaleOfDrs: true,
                allowDrsSalesSol: true,
                allowSaleOfPlanShares: true,
                allowPlanSalesSol: true,
                drsFractionalSaleAllowed: true,
                planFractionalSaleAllowed: true,
                allowSaleReceiptsByACH: false,
                allowSaleReceiptsByInternationalACH: true,
                allowSaleReceiptsByWireTransfer: true,
                salePaymentType: SalePaymentType.Both,
                sellAllPlanSharesTerminatesAccount: true,
              },
            ],
          },
          {
            name: 'J-ones, (&) "Associates.',
            companyId: 411,
            shares: [
              {
                drsTotalWithReserved: 99999.999,
                planTotalWithReserved: 98888.889,
                drsAvailableForSale: 41596.0,
                planAvailableForSale: 0.0,
                certificateAvailableForSale: 0.0,
                securityType: 'COMMON',
                issueNumber: 'PM01',
                drsNumberOfShares: 41596.0,
                planNumberOfShares: 0.0,
                allowSaleOfDrs: true,
                allowDrsSalesSol: false,
                allowSaleOfPlanShares: true,
                allowPlanSalesSol: false,
                drsFractionalSaleAllowed: true,
                planFractionalSaleAllowed: true,
                allowSaleReceiptsByACH: false,
                allowSaleReceiptsByInternationalACH: false,
                allowSaleReceiptsByWireTransfer: true,
                salePaymentType: SalePaymentType.Both,
                sellAllPlanSharesTerminatesAccount: true,
              },
            ],
          },
          {
            name: 'RetraTest',
            companyId: 2319,
            shares: [
              {
                drsTotalWithReserved: 99999.999,
                planTotalWithReserved: 98888.889,
                drsAvailableForSale: 2968.0,
                planAvailableForSale: 0.0,
                certificateAvailableForSale: 0.0,
                securityType: 'COMMON',
                issueNumber: 'WWW1',
                drsNumberOfShares: 2968.0,
                planNumberOfShares: 0.0,
                allowSaleOfDrs: true,
                allowDrsSalesSol: true,
                allowSaleOfPlanShares: true,
                allowPlanSalesSol: true,
                drsFractionalSaleAllowed: false,
                planFractionalSaleAllowed: false,
                allowSaleReceiptsByACH: true,
                allowSaleReceiptsByInternationalACH: true,
                allowSaleReceiptsByWireTransfer: true,
                salePaymentType: SalePaymentType.Both,
                sellAllPlanSharesTerminatesAccount: true,
              },
            ],
          },
        ],
      },
      {
        accountNumber: 4002250787,
        name: 'IRIS,ACCOUNT887',
        value: 15852690.726019999,
        taxCertificationStatus: -1,
        address: 'address',
        primaryAddress: { addressLines: ['TEST', 'TEST MN 55406', '', '', ''] },
        seasonalAddress: {
          addressLines: ['TESTA', 'TESTB TESTC TESTD', 'ALBANIA', '', ''],
          start: '2019-05-02T00:00:00',
          end: '2019-05-03T00:00:00',
          isRecurring: true,
        },
        userRegionCode: '050',
        companies: [],
      },
      {
        accountNumber: 4002350777,
        name: 'IRIS,ACCOUNT847',
        value: 15852690.726019999,
        taxCertificationStatus: -1,
        primaryAddress: { addressLines: ['TEST', 'TEST MN 55406', '', '', ''] },
        seasonalAddress: {
          addressLines: ['TESTA', 'TESTB TESTC TESTD', 'ALBANIA', '', ''],
          start: '2019-05-02T00:00:00',
          end: '2019-05-03T00:00:00',
          isRecurring: true,
        },
        address: 'address',
        userRegionCode: '050',
        companies: [
          {
            name: 'GMR Group',
            companyId: 2315,
            shares: [
              {
                drsTotalWithReserved: 99999.999,
                planTotalWithReserved: 98888.889,
                drsAvailableForSale: 42440.534,
                planAvailableForSale: 1886.793,
                certificateAvailableForSale: 0.0,
                securityType: 'COMMON',
                issueNumber: 'DDD1',
                drsNumberOfShares: 44175.661,
                planNumberOfShares: 1886.793,
                allowSaleOfDrs: true,
                allowDrsSalesSol: true,
                allowSaleOfPlanShares: false,
                allowPlanSalesSol: false,
                drsFractionalSaleAllowed: false,
                planFractionalSaleAllowed: false,
                allowSaleReceiptsByACH: true,
                allowSaleReceiptsByInternationalACH: true,
                allowSaleReceiptsByWireTransfer: true,
                salePaymentType: SalePaymentType.Both,
                sellAllPlanSharesTerminatesAccount: true,
              },
            ],
          },
          {
            name: 'ADR India',
            companyId: 2298,
            shares: [
              {
                drsTotalWithReserved: 99999.999,
                planTotalWithReserved: 98888.889,
                drsAvailableForSale: 614781.92,
                planAvailableForSale: 128428.35,
                certificateAvailableForSale: 0.0,
                securityType: 'COMMON',
                issueNumber: 'IND1',
                drsNumberOfShares: 614781.92,
                planNumberOfShares: 128428.35,
                allowSaleOfDrs: true,
                allowDrsSalesSol: false,
                allowSaleOfPlanShares: true,
                allowPlanSalesSol: true,
                drsFractionalSaleAllowed: false,
                planFractionalSaleAllowed: false,
                allowSaleReceiptsByACH: true,
                allowSaleReceiptsByInternationalACH: true,
                allowSaleReceiptsByWireTransfer: true,
                salePaymentType: SalePaymentType.Both,
                sellAllPlanSharesTerminatesAccount: true,
              },
            ],
          },
          {
            name: 'MUMBAI INDIAN',
            companyId: 2299,
            shares: [
              {
                drsTotalWithReserved: 99999.999,
                planTotalWithReserved: 98888.889,
                drsAvailableForSale: 43891.082,
                planAvailableForSale: 7857.558,
                certificateAvailableForSale: 0.0,
                securityType: 'COMMON',
                issueNumber: 'MUM1',
                drsNumberOfShares: 43891.082,
                planNumberOfShares: 7857.558,
                allowSaleOfDrs: true,
                allowDrsSalesSol: true,
                allowSaleOfPlanShares: true,
                allowPlanSalesSol: true,
                drsFractionalSaleAllowed: true,
                planFractionalSaleAllowed: true,
                allowSaleReceiptsByACH: true,
                allowSaleReceiptsByInternationalACH: true,
                allowSaleReceiptsByWireTransfer: true,
                salePaymentType: SalePaymentType.Both,
                sellAllPlanSharesTerminatesAccount: true,
              },
            ],
          },
          {
            name: 'J-ones, (&) "Associates.',
            companyId: 411,
            shares: [
              {
                drsTotalWithReserved: 99999.999,
                planTotalWithReserved: 98888.889,
                drsAvailableForSale: 41596.0,
                planAvailableForSale: 0.0,
                certificateAvailableForSale: 0.0,
                securityType: 'COMMON',
                issueNumber: 'PM01',
                drsNumberOfShares: 41596.0,
                planNumberOfShares: 0.0,
                allowSaleOfDrs: true,
                allowDrsSalesSol: false,
                allowSaleOfPlanShares: true,
                allowPlanSalesSol: false,
                drsFractionalSaleAllowed: true,
                planFractionalSaleAllowed: true,
                allowSaleReceiptsByACH: true,
                allowSaleReceiptsByInternationalACH: true,
                allowSaleReceiptsByWireTransfer: true,
                salePaymentType: SalePaymentType.Both,
                sellAllPlanSharesTerminatesAccount: true,
              },
            ],
          },
          {
            name: 'RetraTest',
            companyId: 2319,
            shares: [
              {
                drsTotalWithReserved: 99999.999,
                planTotalWithReserved: 98888.889,
                drsAvailableForSale: 2968.0,
                planAvailableForSale: 0.0,
                certificateAvailableForSale: 0.0,
                securityType: 'COMMON',
                issueNumber: 'WWW1',
                drsNumberOfShares: 2968.0,
                planNumberOfShares: 0.0,
                allowSaleOfDrs: false,
                allowDrsSalesSol: false,
                allowSaleOfPlanShares: true,
                allowPlanSalesSol: true,
                drsFractionalSaleAllowed: false,
                planFractionalSaleAllowed: false,
                allowSaleReceiptsByACH: true,
                allowSaleReceiptsByInternationalACH: true,
                allowSaleReceiptsByWireTransfer: true,
                salePaymentType: SalePaymentType.Both,
                sellAllPlanSharesTerminatesAccount: true,
              },
            ],
          },
        ],
      },
    ],
  },
};

export function mockSellAccountResponse(adapter: MockAdapter) {
  const url = APP_CONFIG.api.shares.sell.accounts;
  return adapter.onGet(new RegExp(`${url}(.*)`)).reply(200, RESPONSE);
}
