import React, { useEffect, useCallback } from 'react';
import { RootState } from '../../store/rootModel';
import { connect, DispatchProp } from 'react-redux';
import { unreadMessagesActions } from './store/unreadMessages.actions';
import { UnreadRoundel } from './../UnreadRoundel/UnreadRoundel';

interface OwnProps {
  wrapper?: string;
}

const mapStateToProps = (state: RootState) => ({
  data: state.unreadMessages.data,
  started: state.unreadMessages.started,
});

function Component({
  data,
  started,
  dispatch,
  wrapper = 'div',
}: ReturnType<typeof mapStateToProps> & DispatchProp & OwnProps) {
  const counter = data ? data.inboxMessageCount + data.companyMessageCount : 0;

  const init = useCallback(() => dispatch(unreadMessagesActions.init()), [dispatch]);

  useEffect(() => {
    !started && init();
  }, [started, init]);

  return counter > 0 ? renderComponent() : null;

  function renderComponent() {
    if (wrapper === 'roundel') {
      return <UnreadRoundel>{counter}</UnreadRoundel>;
    }
    return (
      <div className={'unread-messages-badge'} data-testid="unread-messages-badge">
        {counter}
      </div>
    );
  }
}

export const UnreadMessagesBadge = connect(mapStateToProps)(Component);
