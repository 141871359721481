import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Modal } from './Modal.Wrapper';
import { modalActions } from '../store/actions';

interface OwnProps {
  titleText: string;
  footerText: string;
  closeText: string;
  submitText: string;
  content: any;
  onSubmitClick: () => any;
  children: any;
  compact?: boolean;
}

interface ModalContainerProps extends OwnProps {
  dispatch: Dispatch;
}

function BasicModalComponent({
  titleText,
  footerText,
  closeText,
  submitText,
  onSubmitClick,
  content,
  dispatch,
  compact,
  ...rest
}: ModalContainerProps) {
  return (
    <Modal
      titleText={titleText}
      footerText={footerText}
      closeText={closeText}
      submitText={submitText}
      compact={compact}
      onCloseClick={() => dispatch(modalActions.closeModal())}
      onSubmitClick={() => onSubmitClick}
      {...rest as any}
    >
      {content}
    </Modal>
  );
}

export const BasicModal = connect()(BasicModalComponent);
