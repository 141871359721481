import React from 'react';
import { LoadHandler } from '../../../../lib/LoadHandler';
import {
  autoSellConfigInitialValues,
  initialBankDetails,
  SaleType,
  SellAccount,
  SellJourney,
  SellJourneyMetaData,
  SellMethod,
  ShareType,
} from './SellSharesTypes';
import {
  getBookLotsFactory,
  getInternationalCurrency,
  getSelectedAccount,
  getSelectedCompany,
  getSelectedSecurityType,
  getSellAmount,
} from './SellSharesSelectors';
import { useSellSharesAccountsApi } from './SellSharesHooks';

export const SellJourneyInitialData: SellJourney = {
  accountNumber: 0,
  companyId: 0,
  securityType: '',
  issueNumber: '',
  agreesToTerms: false,
  saleType: SaleType.MarketOrder,
  shareTypesToSell: ShareType.None,
  drsSellAmount: 0,
  planSellAmount: 0,
  saleMethod: SellMethod.FIFO,
  ...autoSellConfigInitialValues,
  ...initialBankDetails,
  bookLotSaleConfig: {},
};

export const SellJourneyMetaDataInitialState: SellJourneyMetaData = {
  accounts: [],
  countries: [],
  totalSharesToSell: '',
  isMarketPricingAvailable: true,
  inquiryMode: false,
  getBookLots: () => [],
};

export interface SellJourneyContextState {
  data: SellJourney;
  metaData: SellJourneyMetaData;
}

export interface SellJourneyUpdate {
  update: (data: Partial<SellJourney>, reset?: boolean) => void;
  updateMetaData: (metaData: Partial<SellJourneyMetaData>) => void;
}

export const SellJourneyContext = React.createContext<SellJourneyContextState>({
  data: SellJourneyInitialData,
  metaData: SellJourneyMetaDataInitialState,
});

export const SellJourneyUpdate = React.createContext<SellJourneyUpdate>({
  update: () => {},
  updateMetaData: () => {},
});

interface OwnProps {
  initialState?: SellJourney;
}

export function SellJourneyContextProvider({ children }: React.PropsWithChildren<OwnProps>) {
  const accounts = useSellSharesAccountsApi();
  const [data, setData] = React.useState<SellJourney>(SellJourneyInitialData);
  const [metaData, setMetaData] = React.useState<SellJourneyMetaData>(
    SellJourneyMetaDataInitialState,
  );

  const contextState: SellJourneyContextState = {
    data: data,
    metaData: {
      ...metaData,
      accounts: (accounts.data ? accounts.data.accountCompanies : []) as SellAccount[],
      selectedAccount: getSelectedAccount(data, metaData),
      selectedCompany: getSelectedCompany(data, metaData),
      selectedIssue: getSelectedSecurityType(data, metaData),
      totalSharesToSell: getSellAmount(data),
      internationalCurrency: getInternationalCurrency(data, metaData),
      getBookLots: getBookLotsFactory(data, metaData),
      sharePrice: metaData && metaData.sellTypes && metaData.sellTypes.price,
      isMarketPricingAvailable: !!(accounts.data && accounts.data.isMarketPricingAvailable),
    },
  };

  return (
    <SellJourneyContext.Provider value={contextState}>
      <SellJourneyUpdate.Provider value={{ update, updateMetaData }}>
        <LoadHandler loaders={[accounts]}>{children}</LoadHandler>
      </SellJourneyUpdate.Provider>
    </SellJourneyContext.Provider>
  );

  function update(newData: Partial<SellJourney>, reset: boolean = false) {
    if (reset){
      setData({...data, ...SellJourneyInitialData, ...newData})
    }
    else {
      setData({ ...data, ...newData });
    }
  }

  function updateMetaData(newMetaData: Partial<SellJourneyMetaData>) {
    setMetaData({ ...metaData, ...newMetaData });
  }
}

export function useSellSharesState() {
  const context = React.useContext(SellJourneyContext);

  if (typeof context === 'undefined') throw new Error('useSellSharesState not within context');

  return context;
}

export function useSellSharesUpdate() {
  const context = React.useContext(SellJourneyUpdate);

  if (typeof context === 'undefined') throw new Error('useSellSharesState not within context');

  return context;
}

export function useSellSharesJourney(): SellJourneyContextState & SellJourneyUpdate {
  return { ...useSellSharesState(), ...useSellSharesUpdate() };
}
